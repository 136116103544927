import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { consume } from "@lit/context";
import { styleMap } from "lit/directives/style-map.js";
import { configContext, Config } from "./config-context";
import { mic, micOff } from "./svg";

/**
 * Record button
 *
 * @fires bh-record-button-clicked - This event occurs when user clicks record button
 * @fires bh-transcription - This event occurs when we have a new audio transcription
 */
@customElement("behumans-record-button")
export default class RecordButton extends LitElement {
  private _audioTrack: MediaStreamTrack | null = null;

  private _mediaRecorder: MediaRecorder | null = null;

  private _timeoutId?: ReturnType<typeof setTimeout>;

  private _blobs: Blob[] = [];

  private _preferredMimeType: string = "";

  private _clickThrottling = false;

  @state()
  private _on = false;

  @consume({ context: configContext })
  @property({ attribute: false })
  public config?: Config;

  static override styles = css`
    .rec {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border: 0px;
      border-radius: 50%;
      cursor: pointer;
      -webkit-mask-image: -webkit-radial-gradient(
        circle,
        white 100%,
        black 100%
      );
    }

    .rec > svg {
      fill: white;
    }
  `;

  constructor() {
    super();
    this._preferredMimeType = RecordButton._getPreferredMimeType();
  }

  /**
   * getPreferredMimeType returns our preferred and supported mimetype or an empty string
   */
  private static _getPreferredMimeType() {
    const mimeTypes = [
      "audio/webm;codecs=opus",
      "audio/ogg;codecs=opus",
      "audio/mp4", // Safari
    ];

    if (!MediaRecorder) return "";

    const supportedMimeTypes = mimeTypes.filter((mimeType) =>
      MediaRecorder.isTypeSupported(mimeType)
    );

    if (supportedMimeTypes.length === 0) return "";
    return supportedMimeTypes[0];
  }

  private async _onClick() {
    // throttling double clicks - using 500ms which is the default double click on Windows
    if (this._clickThrottling) return;
    this._clickThrottling = true;
    setTimeout(() => {
      this._clickThrottling = false;
    }, 500);

    this.dispatchEvent(
      new Event("bh-record-button-clicked", {
        bubbles: true,
        composed: true,
      })
    );

    if (!this._audioTrack || !this._mediaRecorder) {
      if (
        !("mediaDevices" in navigator) ||
        !("getUserMedia" in navigator.mediaDevices)
      ) {
        console.warn(
          "cannot access mic: browser doesn't support navigator.mediaDevices.getUserMedia"
        );
        return;
      }
      if (!MediaRecorder) {
        console.warn(
          "cannot record mic: browser doesn't support MediaRecorder"
        );
        return;
      }
      // TODO do not have mic permission or failed to get mic tooltip
      const localStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      [this._audioTrack] = localStream.getAudioTracks();

      // TODO: show tooltip or message to the user that audio is muted
      // we can play with onunmute to discover when audio is available or block mute audio recording at all
      if (this._audioTrack.muted) {
        console.warn("audio is muted");
      }

      this._audioTrack.onended = () => {
        console.warn(
          "user revoked permission or hardware generating the audio input has been removed"
        );
        this._stopRecording();
      };
      this._audioTrack.onmute = () => {
        console.warn("user muted audio input");
        this._stopRecording();
      };

      // If there is no preferredMimeType let mediaRecorder set MimeType/Codec
      this._mediaRecorder = new MediaRecorder(
        localStream,
        this._preferredMimeType ? { mimeType: this._preferredMimeType } : {}
      );
      // update preferredMimeType if mediaRecorder who sets MimeType/Codec
      if (!this._preferredMimeType)
        this._preferredMimeType = this._mediaRecorder.mimeType;

      this._mediaRecorder.ondataavailable = async (ev) => {
        if (ev.data) this._blobs.push(ev.data);
      };

      // Stop event occurs after dataavailable so we have the latest blob
      this._mediaRecorder.onstop = async () => {
        try {
          const audioBlob = new Blob(this._blobs, {
            type: this._preferredMimeType,
          });
          // EMPTY BLOBS ARRAY IN-PLACE!
          this._blobs.length = 0;
          const transcription = await this._getTranscription(audioBlob);
          const event = new CustomEvent<string>("bh-transcription", {
            bubbles: true,
            composed: true,
            detail: transcription,
          });
          this.dispatchEvent(event);
        } catch (err) {
          // TODO: failed to get transcription we should show tooltip or show error response on chat
          console.error(err);
        }
      };
      // it emits ondataavailable with a new blob each 1000ms
      // this timeslice is required to mediaRecorder work on iOS browsers and Safari
      this._mediaRecorder.start(1000);
      this._on = true;

      // Timeout
      this._timeoutId = setTimeout(
        () => this._stopRecording(),
        1000 * (this.config?.micRecorderTimeout || 60)
      ); // default to 60 seconds
    } else {
      this._stopRecording();
    }
  }

  private _stopRecording() {
    this._mediaRecorder?.stop(); // it emits "dataavailable" event and then emits "stop" event
    // release the user mic, state is changed to "ended" and will never provide new data.
    // ended event is not fired on calling stop()
    this._audioTrack?.stop();
    this._audioTrack = null;
    this._mediaRecorder = null;
    this._on = false;
    clearTimeout(this._timeoutId);
  }

  /**
   * getPreferredMimeType returns our preferred and supported mimetype or an empty string
   */
  private async _getTranscription(blob: Blob): Promise<string> {
    interface SpeechToTextResp {
      text: string;
    }

    const url = new URL(`${this.config?.apiUrl}/api/v1/speech-to-text`);
    if (this.config?.language)
      url.searchParams.set("language", this.config?.language);

    const resp = await fetch(url.toString(), {
      method: "POST",
      body: blob,
      headers: {
        Authorization: `Bearer ${this.config?.token}`,
        "bh-project-id": this.config?.projectId || "",
        "bh-agent-id": this.config?.agentId || "",
        "bh-session-id": this.config?.sessionId || "",
      },
    });

    if (!resp.ok) {
      throw new Error("failed to get transcription");
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const json: SpeechToTextResp = await resp.json();

    return json.text;
  }

  override render() {
    const recordButtonStyle = {
      backgroundColor: this._on
        ? "var(--bh-conversation-recording-button-color, #f1255c)"
        : "var(--bh-conversation-record-button-color, #52050A)",
    };

    return html`
      <button
        id="record"
        class="rec"
        style=${styleMap(recordButtonStyle)}
        @click=${() => this._onClick()}
      >
        <svg height="24" viewBox="0 -960 960 960" width="24">
          ${this._on ? mic : micOff}
        </svg>
      </button>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "behumans-record-button": RecordButton;
  }
}
