import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { styleMap } from "lit/directives/style-map.js";
import "@material/web/button/text-button";
/**
 * BeHumans Avatar Tooltip
 *
 * If a \<template id="bh-avatar-tooltip-template"> is defined
 * use template content instead of default content
 */
@customElement("behumans-avatar-tooltip")
export default class AvatarTooltip extends LitElement {
  static override styles = css`
    .container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 100%;
    }

    md-text-button {
      align-self: end;
    }
  `;

  @state()
  customContent?: Node;

  @property({ attribute: "show-agreement-tooltip", type: Boolean })
  public showAgreementTooltip = false;

  override connectedCallback() {
    super.connectedCallback();

    const template = document.querySelector("#bh-avatar-tooltip-template");
    if (template) {
      if (template instanceof HTMLTemplateElement && "content" in template) {
        this.customContent = template.content.cloneNode(true);
      } else {
        console.warn("#bh-avatar-tooltip-template should be a <template>");
      }
    }
  }

  private _handleClick() {
    // Fires tooltip agreed event
    const event = new Event("bh-avatar-agreement-tooltip-agreed", {
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(event);
  }

  static defaultContent = html` <h4>
      Soy un <b>Humano Digital</b>,
      <p>
        y voy a ser tu asistente si lo deseas.<br /><br />Para mejorar tu
        experiencia, por favor, considera:
      </p>
    </h4>

    <ul>
      <li>Para hablar debes pulsar el símbolo del micrófono.</li>
      <li>Cuando hayas terminado tu pregunta, debes pulsarlo de nuevo.</li>
      <li>
        Puedes hablar a través del micrófono o a través de texto (haciendo click
        en el botón azul derecho).
      </li>
    </ul>`;

  override render() {
    return html`<div class="container">
      <div>${this.customContent || AvatarTooltip.defaultContent}</div>
      <md-text-button
        @click=${() => this._handleClick()}
        style=${styleMap({
          visibility: this.showAgreementTooltip ? "visible" : "hidden",
        })}
        >Entendido</md-text-button
      >
    </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "behumans-avatar-tooltip": AvatarTooltip;
  }
}
