import { LitElement, html, css } from "lit";
import { customElement, property, state, query } from "lit/decorators.js";
import { styleMap } from "lit/directives/style-map.js";
import { consume } from "@lit/context";
import { configContext, Config } from "./config-context";
import "./behumans-record-button";
import "./behumans-avatar-tooltip";

/**
 * BeHumans Avatar.
 *
 * @fires bh-avatar-tooltip-showed - This event occurs when mouse pointer enters the avatar and avatar tooltip is shown
 */
@customElement("behumans-avatar")
export default class Avatar extends LitElement {
  static override styles = css`
    behumans-avatar-tooltip {
      position: fixed;
      bottom: 200px;
      width: 323px;
      background: #ffffff;
      margin: 40px auto;
      padding: 15px;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      font-size: 14px;
    }

    .circle {
      position: fixed;
      width: 100px;
      height: 132px;
      bottom: 65px;
      background: transparent;
      border-radius: 125px;
      margin: 0 auto;
      z-index: 2;
      -webkit-mask-image: -webkit-radial-gradient(
        circle,
        white 100%,
        black 100%
      );
      cursor: pointer;
    }

    .avatar {
      position: fixed;
      bottom: 0px;
      height: 250px;
      width: 200px;
      border-radius: 50%;
      -webkit-mask-image: -webkit-radial-gradient(
        circle,
        white 100%,
        black 100%
      );
    }

    video {
      width: 200px;
      height: auto;
      aspect-ratio: 1 / 1;
      position: absolute;
      top: 30px;
      cursor: pointer;
      border-radius: 50%;
      object-fit: cover;
      transition: opacity 0.5s ease-in-out 0s;
    }

    behumans-record-button {
      position: relative;
      bottom: 40px;
      right: 145px;
    }
  `;

  @consume({ context: configContext })
  @property({ attribute: false })
  public config?: Config;

  @property({ type: Boolean })
  expanded = false;

  @state()
  private _avatarTooltipVisibility = false;

  @state()
  private _videoLoop = true;

  @property({ attribute: "project-id" })
  projectId = "";

  @property({ attribute: "show-agreement-tooltip", type: Boolean })
  public showAgreementTooltip = false;

  @state()
  private _videoEngageUrl = "";

  @query("#video_engage")
  private videoEngage?: HTMLVideoElement;

  playVideo(filename: string) {
    switch (filename) {
      case "":
        break;
      case this._videoEngageUrl:
        // Action to play video comes with last video played, just play it again
        if (this.videoEngage) {
          this.videoEngage.currentTime = 0; // Play from the beginning
          this.videoEngage.play().catch((err) => console.error(err));
        }
        break;
      default:
        // Default action to play video
        this._videoEngageUrl = filename;
    }
  }

  stopVideo() {
    this.videoEngage?.pause();
    this._videoLoop = true;
  }

  override render() {
    return html`
      <behumans-avatar-tooltip
        ?show-agreement-tooltip=${this.showAgreementTooltip}
        style=${styleMap({
          visibility:
            this.showAgreementTooltip || this._avatarTooltipVisibility
              ? "visible"
              : "hidden",
          right: this.expanded ? "420px" : "30px",
        })}
      ></behumans-avatar-tooltip>
      <div
        class="avatar"
        style=${styleMap({
          right: this.expanded ? "400px" : "30px",
        })}
      >
        <video
          id="video_loop"
          muted
          autoplay
          loop
          playsinline
          style=${styleMap({
            opacity: this._videoLoop ? 1 : 0,
            zIndex: this._videoLoop ? 2 : 1,
          })}
        >
          <source
            src=${`${this.config?.staticUrl}/${this.projectId}/video/loop.mp4`}
            type="video/mp4"
          />
        </video>
        <video
          id="video_engage"
          @canplay=${() => {
            this._videoLoop = false;
          }}
          @ended=${() => {
            this._videoLoop = true;
          }}
          @pause=${() => {
            this._videoLoop = true;
          }}
          autoplay
          playsinline
          style=${styleMap({
            opacity: this._videoLoop ? 0 : 1,
            zIndex: this._videoLoop ? 1 : 2,
          })}
          src=${this._videoEngageUrl &&
          `${this.config?.staticUrl}/${this.projectId}/video/${this._videoEngageUrl}`}
        ></video>

        <div
          id="circleInfo"
          class="circle"
          style=${styleMap({
            right: this.expanded ? "450px" : "80px",
          })}
          @mouseenter=${() => {
            this._avatarTooltipVisibility = true;

            // Fires tooltip showed event
            const event = new Event("bh-avatar-tooltip-showed");
            this.dispatchEvent(event);
          }}
          @mouseleave=${() => {
            this._avatarTooltipVisibility = false;
          }}
        ></div>
      </div>
      <behumans-record-button
        @click=${() => {
          if (!this._videoLoop) {
            // When user press record button, if playing videoEngage, stop it and show loop video.
            this.videoEngage?.pause();
          }
        }}
      ></behumans-record-button>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "behumans-avatar": Avatar;
  }
}
