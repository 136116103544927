import { LitElement, html } from "lit";
import { customElement, property, state, query } from "lit/decorators.js";
import { provide } from "@lit/context";
import { configContext, Config } from "./config-context";
// eslint-disable-next-line import/extensions
import "https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1";
import "./behumans-avatar";

const DFMESSENGERID = "behumans-df-messenger";

interface DfMessengerLoaded extends Element {
  renderCustomText: (str: string) => void;
  sessionId: string;
}

interface Response {
  response: {
    queryResult: {
      fulfillmentMessages: (
        | {
            text: { text: string[] };
          }
        | {
            payload: {
              action: string;
              value: string;
            };
          }
      )[];
    };
  };
}

function getDocumentHeight() {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight
  );
}

/**
 * BeHumans Conversation element.
 *
 * @fires bh-user-interacted - This event occurs when the user interacts
 * @fires bh-user-input-entered - This event occurs when the user enters an input (text or audio)
 * @fires bh-chat-accordion-clicked - This event occurs when a user clicks an accordion within the chat
 * @fires bh-chat-button-clicked - This event occurs when a user clicks a button within the chat
 * @fires bh-chat-chip-clicked - This event occurs when a user selects a suggestion chip within the chat
 * @fires bh-chat-info-card-clicked - This event occurs when the user clicks the information item in the titlebar within the chat
 * @fires bh-chat-list-element-clicked - This event occurs when a user clicks an item in a list within the chat
 */
@customElement("behumans-conversation")
export default class BehumansConversation extends LitElement {
  // This component is not using shadow DOM because of <df-messenger> and it should act only as a wrapper.

  // Internal Properties
  private _dfMessengerInput?: HTMLInputElement;

  private _dfMessengerSend?: Element;

  private _userInteracted = false;

  @query("behumans-avatar")
  private _behumansAvatar?: HTMLElementTagNameMap["behumans-avatar"];

  // Properties
  @property()
  public token = "";

  @property({ attribute: "project-id" })
  public projectId = "";

  @property({ attribute: "language" })
  public language = "es";

  @property({ attribute: "agent-id" })
  public agentId = "";

  @property({ attribute: "chat-title" })
  public chatTitle = "";

  @property({ attribute: "mic-recorder-timeout", type: Number })
  public micRecorderTimeout?: number; // seconds

  // It cannot set default value here since this.config.staticUrl is undefined here
  // Instead default value is set below within render()
  @property()
  public icon = "";

  // Atention: rollup changes the default domain on dev and staging
  @property({ attribute: "domain" })
  public domain = "conversation.behumans.com"; // changed on bundling proccess.

  @property({ attribute: "show-agreement-tooltip", type: Boolean })
  public showAgreementTooltip = false;

  // Config Context
  @provide({ context: configContext })
  @state()
  private _config: Config = {
    language: "es",
  };

  // State
  @state()
  private _expanded = false;

  // we use this variable to know if the user has expanded the chat before
  // so we can respect the user decision and not force an expand action against its will
  private _hasExpanded = false;

  // static override styles = css``;
  // it doesn't work in this component because we don't create a scoped shadow dom
  // source: https://github.com/lit/lit-element/issues/797

  // We render the template into the main DOM tree as our element's children
  // instead of creating an open shadow root because we had conflicts between the shadow root and Dialog Flow df-messenger component.
  override createRenderRoot() {
    return this;
  }

  override connectedCallback() {
    super.connectedCallback();
    this._config.token = this.token;
    this._config.projectId = this.projectId;
    this._config.agentId = this.agentId;
    this._config.staticUrl = `https://static.${this.domain}`;
    this._config.apiUrl = `https://api.${this.domain}`;
    this._config.language = this.language;
    this._config.micRecorderTimeout = this.micRecorderTimeout;
  }

  // TODO: emit event when our component is ready/loaded
  private _onMessengerLoaded() {
    // Using this event instead of firstUpdated because some properties of df-messenger
    // are only available here, probably because some async internal behaviour.
    const dfMessenger = this.querySelector<DfMessengerLoaded>(
      `#${DFMESSENGERID}`
    );
    if (dfMessenger === null || dfMessenger.shadowRoot === null) {
      // TODO: print error and graceful hide element
      return;
    }

    // Set config sessionId
    this._config.sessionId = dfMessenger.sessionId;

    // TODO: export method to local render custom text
    // dfMessenger.renderCustomText("_onMessengerLoaded");

    // Get Widget Icon
    const widgetIcon = dfMessenger.shadowRoot.getElementById("widgetIcon");
    if (widgetIcon === null) {
      // TODO
      return;
    }
    // Expand Event when click Widget Icon
    widgetIcon.addEventListener("click", () => {
      this._expanded = !this._expanded;
      if (!this._hasExpanded) {
        this._hasExpanded = true;
      }
      this._fireUserInteracted();
    });

    const dfMessengerChat =
      dfMessenger.shadowRoot.querySelector("df-messenger-chat");
    if (dfMessengerChat === null || dfMessengerChat.shadowRoot === null) {
      // TODO
      return;
    }
    const userInput = dfMessengerChat.shadowRoot.querySelector(
      "df-messenger-user-input"
    );
    if (userInput === null || userInput.shadowRoot === null) {
      // TODO
      return;
    }

    const input = userInput.shadowRoot.querySelector("input");
    const sendIcon = userInput.shadowRoot.querySelector("#sendIcon");
    if (sendIcon === null || input === null) {
      // TODO
      return;
    }
    this._dfMessengerInput = input;
    this._dfMessengerSend = sendIcon;
  }

  private _onResponseReceived(e: CustomEvent<Response>) {
    e.detail.response.queryResult.fulfillmentMessages.forEach((entry) => {
      if ("payload" in entry) {
        switch (entry.payload.action) {
          case "Video":
            this._behumansAvatar?.playVideo(entry.payload.value);
            break;
          case "Position":
            window.scrollBy({
              top:
                getDocumentHeight() * Number(entry.payload.value) -
                document.documentElement.scrollTop,
              behavior: "smooth",
            });
            break;
          case "GoToElement":
            // Only allow update href to elements on the same page
            if (entry.payload.value[0] === "#") {
              window.location.href = entry.payload.value;
            }
            break;
          case "expandChat":
            // we should try to respect the user preference, only expand if the user doesn't have it expanded before
            if (!this._hasExpanded) {
              this._expanded = true;
              this._hasExpanded = true;
            }
            break;
          default:
            break;
        }
      }
    });
  }

  private _sendMessage(message: string) {
    if (this._dfMessengerInput && this._dfMessengerSend) {
      this._dfMessengerInput.value = message;
      this._dfMessengerSend.dispatchEvent(new Event("click"));
    }
  }

  private _fireUserInteracted() {
    // Only fire once
    if (!this._userInteracted) {
      const event = new CustomEvent("bh-user-interacted");

      this.dispatchEvent(event);
      this._userInteracted = true;
    }
  }

  private _handleDfUserInputEntered() {
    const event = new CustomEvent("bh-user-input-entered");
    this.dispatchEvent(event);
  }

  private _handleDfAccordionClicked() {
    const event = new CustomEvent("bh-chat-accordion-clicked");

    this.dispatchEvent(event);
  }

  private _handleDfButtonClicked() {
    const event = new CustomEvent("bh-chat-button-clicked");

    this.dispatchEvent(event);
  }

  private _handleDfChipClicked() {
    const event = new CustomEvent("bh-chat-chip-clicked");

    this.dispatchEvent(event);
  }

  private _handleDfInfoCardClicked() {
    const event = new CustomEvent("bh-chat-info-card-clicked");

    this.dispatchEvent(event);
  }

  private _handleDfListElementClicked() {
    const event = new CustomEvent("bh-chat-list-element-clicked");

    this.dispatchEvent(event);
  }

  override render() {
    return html`
      <!-- IMPORTANT: THIS INLINE STYLE DOESN'T HAVE SCOPE SO THEY LEAK! BE CAREFUL!-->
      <style>
        behumans-avatar {
          position: fixed;
          z-index: 100;
          bottom: -20px;
          right: ${this._expanded ? "400px" : "30px"};
        }
        /* TODO: maybe instead of apply over element, apply over some more specific CSS selector */
        /* DFMESSENGERID */
        df-messenger {
          /* TODO: expose CSS colors */
          --df-messenger-button-titlebar-color: var(
            --bh-conversation-button-titlebar-color,
            #001ec8
          );
          --df-messenger-button-titlebar-font-color: var(
            --bh-conversation-button-titlebar-font-color,
            #fff
          );
          --df-messenger-bot-message: var(
            --bh-conversation-avatar-message,
            #fff
          );
          --df-messenger-chat-background-color: var(
            --bh-conversation-chat-background-color,
            #fafafa
          );
          --df-messenger-font-color: var(
            --bh-conversation-font-color,
            rgba(0, 0, 0, 0.87)
          );
          --df-messenger-input-box-color: var(
            --bh-conversation-input-box-color,
            #fff
          );
          --df-messenger-input-font-color: var(
            --bh-conversation-input-font-color,
            rgba(0, 0, 0, 0.87)
          );
          --df-messenger-input-placeholder-font-color: var(
            --bh-conversation-input-placeholder-font-color,
            #757575
          );
          --df-messenger-minimized-chat-close-icon-color: var(
            --bh-conversation-minimized-chat-close-icon-color,
            rgba(0, 0, 0, 0.87)
          );
          --df-messenger-send-icon: var(--bh-conversation-send-icon, #001ec8);
          --df-messenger-user-message: var(
            --bh-conversation-user-message,
            #ddd
          );
        }
      </style>
      <behumans-avatar
        ?expanded=${this._expanded}
        ?show-agreement-tooltip=${this.showAgreementTooltip}
        project-id=${this.projectId}
        @bh-record-button-clicked=${() => this._fireUserInteracted()}
        @bh-avatar-tooltip-showed=${() => this._fireUserInteracted()}
        @bh-transcription=${(e: CustomEvent<string>) =>
          this._sendMessage(e.detail)}
        @bh-avatar-agreement-tooltip-agreed=${() => {
          this.showAgreementTooltip = false;
        }}
      ></behumans-avatar>
      <!-- TODO logo has a small delay, request logo using fetch and pass a local url with content here -->
      <df-messenger
        id=${DFMESSENGERID}
        chat-icon=${this.icon || `${this._config.staticUrl}/image/logo.png`}
        chat-title=${this.chatTitle}
        agent-id=${this.agentId}
        language-code="es"
        @df-messenger-loaded=${() => this._onMessengerLoaded()}
        @df-response-received=${(e: CustomEvent<Response>) =>
          this._onResponseReceived(e)}
        @df-user-input-entered=${() => {
          this._handleDfUserInputEntered();
          // Stop engage video and play loop when user sends new message so we can
          // wait and play the new video when response arrives without glitches
          this._behumansAvatar?.stopVideo();
        }}
        @df-accordion-clicked=${() => this._handleDfAccordionClicked()}
        @df-button-clicked=${() => this._handleDfButtonClicked()}
        @df-chip-clicked=${() => this._handleDfChipClicked()}
        @df-info-card-clicked=${() => this._handleDfInfoCardClicked()}
        @df-list-element-clicked=${() => this._handleDfListElementClicked()}
        ?expand=${this._expanded}
      ></df-messenger>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "behumans-conversation": BehumansConversation;
  }
}
