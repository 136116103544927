import { createContext } from "@lit/context";

export interface Config {
  staticUrl?: string;
  apiUrl?: string;
  token?: string;
  sessionId?: string;
  agentId?: string;
  projectId?: string;
  language: string;
  micRecorderTimeout?: number;
}

// eslint-disable-next-line import/prefer-default-export
export const configContext = createContext<Config>("config");
